const dice = [
    {
        "id": 1,
        "type": "regular",
        "design": "Arboretum",
        "stone": "Green Turquoise",
        "rarity": "common"
    },
    {
        "id": 1,
        "type": "foil",
        "design": "Arboretum",
        "stone": "Green Turquoise",
        "rarity": "common"
    },
    {
        "id": 2,
        "type": "regular",
        "design": "Hyro",
        "stone": "Black Network Agate",
        "rarity": "common"
    },
    {
        "id": 2,
        "type": "foil",
        "design": "Hyro",
        "stone": "Black Network Agate",
        "rarity": "common"
    },
    {
        "id": 3,
        "type": "regular",
        "design": "80's",
        "stone": "Green Aventurine",
        "rarity": "common"
    },
    {
        "id": 3,
        "type": "foil",
        "design": "80's",
        "stone": "Green Aventurine",
        "rarity": "common"
    },
    {
        "id": 4,
        "type": "regular",
        "design": "Sakura",
        "stone": "White Lavender Jade",
        "rarity": "common"
    },
    {
        "id": 4,
        "type": "foil",
        "design": "Sakura",
        "stone": "White Lavender Jade",
        "rarity": "common"
    },
    {
        "id": 5,
        "type": "regular",
        "design": "Sylvan",
        "stone": "Kambaba Jasper",
        "rarity": "common"
    },
    {
        "id": 5,
        "type": "foil",
        "design": "Sylvan",
        "stone": "Kambaba Jasper",
        "rarity": "common"
    },
    {
        "id": 6,
        "type": "regular",
        "design": "Art Deco",
        "stone": "Red Granite",
        "rarity": "common"
    },
    {
        "id": 6,
        "type": "foil",
        "design": "Art Deco",
        "stone": "Red Granite",
        "rarity": "common"
    },
    {
        "id": 7,
        "type": "regular",
        "design": "Aestas",
        "stone": "Picture Jasper",
        "rarity": "common"
    },
    {
        "id": 7,
        "type": "foil",
        "design": "Aestas",
        "stone": "Picture asper",
        "rarity": "common"
    },
    {
        "id": 8,
        "type": "regular",
        "design": "Autumnus",
        "stone": "Orange Cat's Eye",
        "rarity": "common"
    },
    {
        "id": 8,
        "type": "foil",
        "design": "Autumnus",
        "stone": "Orange Cat's Eye",
        "rarity": "common"
    },
    {
        "id": 9,
        "type": "regular",
        "design": "Ver",
        "stone": "Green Cat's Eye",
        "rarity": "common"
    },
    {
        "id": 9,
        "type": "foil",
        "design": "Ver",
        "stone": "Green Cat's Eye",
        "rarity": "common"
    },
    {
        "id": 10,
        "type": "regular",
        "design": "Hiems",
        "stone": "Blue Turquoise",
        "rarity": "common"
    },
    {
        "id": 10,
        "type": "foil",
        "design": "Hiems",
        "stone": "Blue Turquoise",
        "rarity": "common"
    },
    {
        "id": 11,
        "type": "regular",
        "design": "Eclipsed",
        "stone": "Opalite",
        "rarity": "common"
    },
    {
        "id": 11,
        "type": "foil",
        "design": "Eclipsed",
        "stone": "Opalite",
        "rarity": "common"
    },
    {
        "id": 12,
        "type": "regular",
        "design": "(>'.'<)",
        "stone": "Ivory Jade",
        "rarity": "common"
    },
    {
        "id": 12,
        "type": "foil",
        "design": "(>'.'<)",
        "stone": "Ivory Jade",
        "rarity": "common"
    },
    {
        "id": 13,
        "type": "regular",
        "design": "Pupper",
        "stone": "Mahogany Obsidian",
        "rarity": "common"
    },
    {
        "id": 13,
        "type": "foil",
        "design": "Pupper",
        "stone": "Mahogany Obsidian",
        "rarity": "common"
    },
    {
        "id": 14,
        "type": "regular",
        "design": "Armourer",
        "stone": "Oceanic Marble",
        "rarity": "common"
    },
    {
        "id": 14,
        "type": "foil",
        "design": "Armourer",
        "stone": "Oceanic Marble",
        "rarity": "common"
    },
    {
        "id": 15,
        "type": "regular",
        "design": "Vyzor",
        "stone": "Black Cat's Eye",
        "rarity": "common"
    },
    {
        "id": 15,
        "type": "foil",
        "design": "Vyzor",
        "stone": "Black Cat's Eye",
        "rarity": "common"
    },
    {
        "id": 16,
        "type": "regular",
        "design": "Cyber",
        "stone": "Black Cat's Eye",
        "rarity": "common"
    },
    {
        "id": 16,
        "type": "foil",
        "design": "Cyber",
        "stone": "Black Cat's Eye",
        "rarity": "common"
    },
    {
        "id": 17,
        "type": "regular",
        "design": "Shadow Masque",
        "stone": "Bloodstone",
        "rarity": "uncommon"
    },
    {
        "id": 17,
        "type": "foil",
        "design": "Shadow Masque",
        "stone": "Bloodstone",
        "rarity": "uncommon"
    },
    {
        "id": 18,
        "type": "regular",
        "design": "stained glass",
        "stone": "Clear Quartz",
        "rarity": "uncommon"
    },
    {
        "id": 18,
        "type": "foil",
        "design": "stained glass",
        "stone": "Clear Quartz",
        "rarity": "uncommon"
    },
    {
        "id": 19,
        "type": "regular",
        "design": "Chip",
        "stone": "Raised Obsidian",
        "rarity": "uncommon"
    },
    {
        "id": 19,
        "type": "foil",
        "design": "Chip",
        "stone": "Raised Obsidian",
        "rarity": "uncommon"
    },
    {
        "id": 20,
        "type": "regular",
        "design": "Bhyve",
        "stone": "Citrine",
        "rarity": "uncommon"
    },
    {
        "id": 20,
        "type": "foil",
        "design": "Bhyve",
        "stone": "Citrine",
        "rarity": "uncommon"
    },
    {
        "id": 21,
        "type": "regular",
        "design": "Krane",
        "stone": "Malachite",
        "rarity": "uncommon"
    },
    {
        "id": 21,
        "type": "foil",
        "design": "Krane",
        "stone": "Malachite",
        "rarity": "uncommon"
    },
    {
        "id": 22,
        "type": "regular",
        "design": "Daruma",
        "stone": "Phoenix Lapis",
        "rarity": "uncommon"
    },
    {
        "id": 22,
        "type": "foil",
        "design": "Daruma",
        "stone": "Phoenix Lapis",
        "rarity": "uncommon"
    },
    {
        "id": 23,
        "type": "regular",
        "design": "Grace",
        "stone": "Tiger's Eye",
        "rarity": "uncommon"
    },
    {
        "id": 23,
        "type": "foil",
        "design": "Grace",
        "stone": "Tiger's Eye",
        "rarity": "uncommon"
    },
    {
        "id": 24,
        "type": "regular",
        "design": "中秋節",
        "stone": "Blue Cat's Eye",
        "rarity": "uncommon"
    },
    {
        "id": 24,
        "type": "foil",
        "design": "中秋節",
        "stone": "Blue Cat's Eye",
        "rarity": "uncommon"
    },
    {
        "id": 25,
        "type": "regular",
        "design": "Celtic",
        "stone": "African Jade",
        "rarity": "uncommon"
    },
    {
        "id": 25,
        "type": "foil",
        "design": "Celtic",
        "stone": "African Jade",
        "rarity": "uncommon"
    },
    {
        "id": 26,
        "type": "regular",
        "design": "HalloweenKin",
        "stone": "Red Aventurine",
        "rarity": "rare"
    },
    {
        "id": 26,
        "type": "foil",
        "design": "HalloweenKin",
        "stone": "Red Aventurine",
        "rarity": "rare"
    },
    {
        "id": 27,
        "type": "regular",
        "design": "Constellation",
        "stone": "Raised HoloGlass",
        "rarity": "rare"
    },
    {
        "id": 27,
        "type": "foil",
        "design": "Constellation",
        "stone": "Raised HoloGlass",
        "rarity": "rare"
    },
    {
        "id": 28,
        "type": "regular",
        "design": "Kaiser",
        "stone": "Amethyst",
        "rarity": "rare"
    },
    {
        "id": 28,
        "type": "foil",
        "design": "Kaiser",
        "stone": "Amethyst",
        "rarity": "rare"
    },
    {
        "id": 29,
        "type": "regular",
        "design": "Vizier",
        "stone": "Pink Cat's Eye",
        "rarity": "rare"
    },
    {
        "id": 29,
        "type": "foil",
        "design": "Vizier",
        "stone": "Pink Cat's Eye",
        "rarity": "rare"
    },
    {
        "id": 30,
        "type": "regular",
        "design": "Lotus",
        "stone": "Obsidian",
        "rarity": "rare"
    },
    {
        "id": 30,
        "type": "foil",
        "design": "Lotus",
        "stone": "Obsidian",
        "rarity": "rare"
    },
    {
        "id": 31,
        "type": "regular",
        "design": "Samhein",
        "stone": "Ruby in Zoisite",
        "rarity": "rare"
    },
    {
        "id": 31,
        "type": "foil",
        "design": "Samhein",
        "stone": "Ruby in Zoisite",
        "rarity": "rare"
    },
    {
        "id": 32,
        "type": "regular",
        "design": "Widow",
        "stone": "Raised HoloGlass",
        "rarity": "rare"
    },
    {
        "id": 32,
        "type": "foil",
        "design": "Widow",
        "stone": "Raised HoloGlass",
        "rarity": "rare"
    }
];


export default dice;
