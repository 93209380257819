import Links from './links';
import DiceContainer from "./dice-container";
import secretImg from "../assets/img/fullres/gbb2-33.jpg";

export default function Home() {


    return (
        <div className="content">
            <div className="intro">
                <h2>Level Up Dice is excited to BPOOP our most stunning retail partnership of 2021<a
                    data-fancybox="secret-gallery" href={secretImg}
                    data-caption="&#9679; 33 <br> Crystal Dragon <br> Crown Crystal <br> (Good Games Box Topper exclusive)">;</a>
                    <br/> <span className="bold">The Glyphic Blind Bag Series 2!</span></h2>
                <Links/>
                <br/>
                <p>Glyphic Blind Bag Series 2 includes <span className="bold">32 new designs</span> (with
                    additional chase
                    foils of each)!</p>

                <p>Browse below to see all the designs of Series 2. You can <span
                    className="bold">use the buttons to sort</span> by rarity as well as foiled.</p>

                <p><span className="bold">Add to your collection</span> as you get them and our site will use the magic
                    of cookies to keep track for when you come back!</p>

                <div className="cookies-breakout">With the release of GBB3 and the change of URL, cookies have been
                    reset
                </div>

            </div>
            <div className="interaction-container">
                <div className="rarity-container">
                    <h3>Each pack has a</h3>
                    <div>
                        <div><h4>55% chance</h4><p>of being <b>common</b></p></div>
                        <div><h4>35% chance</h4><p>of being <b>uncommon</b></p></div>
                        <div><h4>10% chance</h4><p>of being <b>rare</b></p></div>
                    </div>
                </div>
                {/*<div className="progress-container">*/}
                {/*    <h3>Collection</h3>*/}
                {/*    <h1>...</h1>*/}
                {/*</div>*/}
            </div>
            <hr/>
            <DiceContainer/>
        </div>

    )
}


