import {Link} from 'react-router-dom';
import React, {useState, useEffect} from 'react'

import axios from 'axios';


export default function FLGS() {

    const [flgs, setFlgs] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        axios.get('/data-flgs.json')
            .then(res => {
                setFlgs(res.data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    function handleCountryChange(e) {
        let countryButtons = document.querySelectorAll('.filters .country button');
        for (let i = 0; i < countryButtons.length; i++) {
            countryButtons[i].classList.remove('active');
        }
        e.target.classList.add('active');
        let country = e.target.innerHTML.toLowerCase();
        if (country === 'all') {
            country = ''
        }
        //do stuff to filter
        let flgsCards = document.querySelectorAll('.flgs-card');
        for (let i = 0; i < flgsCards.length; i++) {
            // console.log(flgsCards[i].dataset.country)
            if (flgsCards[i].dataset.country.indexOf(country) > -1) {
                flgsCards[i].style.display = "block";
            } else {
                flgsCards[i].style.display = "none";
            }
        }
    }


    if (isLoading) {
        return (
            <div className="content">
                <h4>Loading...</h4>
            </div>
        )
    } else {
        return (
            <div className="content">
                <Link className="back" to="/"> Back to the dice!</Link>
                <div className="filters">
                    <div className="filter country">
                        <h4>Country:</h4>
                        <button className="active" onClick={handleCountryChange}>all</button>
                        <button onClick={handleCountryChange}>USA</button>
                        <button onClick={handleCountryChange}>AUS</button>
                        <button onClick={handleCountryChange}>Intl</button>
                    </div>
                </div>
                <div className="addflgs">
                    <p>Don't see your FLGS? Let them know they can contact us at <a
                        href="mailto:gbb@levelupdice.net">gbb@levelupdice.net</a> to be a part of our June wave!</p>
                </div>
                <br/>
                <div className="flgs-container">
                    {Object.entries(flgs).map((store, index) => (
                        <div className="flgs-card" data-country={store[1].country} key={index}>
                            <a target="_blank" rel="noreferrer" className="store_name" href={store[1].website}>
                                <h4>{store[0]}</h4>
                            </a>
                            <a target="_blank" rel="noreferrer" className="store_address" href={store[1].map}>
                                <p>{store[1].address}</p></a>
                        </div>
                    ))}
                </div>
                <br/>
            </div>
        )
    }


}


