import imgHeader from "../assets/img/header.png";

export default function Header() {


    return (
        <header className="App-header">
            <div className="breakout breakout-left">
                <a href="http://gbb3.levelupdice.net"> &lt; GBB Series 3</a>
            </div>
            <div className="breakout breakout-right">
                <a target="_blank" rel="noreferrer" href="http://gbb1.levelupdice.net">GBB Alpha &gt;</a>
            </div>
            <img className="img-header" src={imgHeader} alt="Glyphic Blind Bag Series 2"
                 title="Glyphic Blind Bag Series 2"/>
        </header>
    )
}
